// Models
import {AppPartnerIndex} from "@/models/app/partner";

export interface AppOutgoingInvoiceIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    paymentDescription: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  }
  created_at: string;
  updated_at: string;
  partner: AppPartnerIndex;
}

export class AppOutgoingInvoiceIndex implements AppOutgoingInvoiceIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    paymentDescription: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  }
  created_at: string;
  updated_at: string;
  partner: AppPartnerIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.partner_id = "";
    this.content = {
      year: "",
      invoiceNumber: "",
      invoiceNumberInYear: "",
      paymentDescription: "",
      amount: {
        hrk: "",
        eur: "",
      },
      paidAmount: {
        hrk: "",
        eur: "",
      },
      status: "0",
      date: "",
      dateOfExchangeRate: "",
      dateOfClosing: "",
    };
    this.created_at = "";
    this.updated_at = "";
    this.partner = new AppPartnerIndex();
  }
}