// Models
import {AppCountyIndex} from "@/models/app/county";

export interface AppPlaceIndex {
  id: string;
  organization_id: string | string[];
  county_id: string | string[];
  content: {
    title: string;
    postCode: string;
  }
  created_at: string;
  updated_at: string;
  county: AppCountyIndex;
}

export class AppPlaceIndex implements AppPlaceIndex {
  id: string;
  organization_id: string | string[];
  county_id: string | string[];
  content: {
    title: string;
    postCode: string;
  }
  created_at: string;
  updated_at: string;
  county: AppCountyIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.county_id = "";
    this.content = {
      title: "",
      postCode: "",
    };
    this.created_at = "";
    this.updated_at = "";
    this.county = new AppCountyIndex();
  }
}