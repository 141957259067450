export interface AppBankIndex {
  id: string;
  organization_id: string | string[];
  content: {
    title: string;
    postCode: string;
    leadingNumber: string;
    swift: string;
  }
  created_at: string;
  updated_at: string;
}

export class AppBankIndex implements AppBankIndex {
  id: string;
  organization_id: string | string[];
  content: {
    title: string;
    postCode: string;
    leadingNumber: string;
    swift: string;
  }
  created_at: string;
  updated_at: string;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      title: "",
      postCode: "",
      leadingNumber: "",
      swift: "",
    };
    this.created_at = "";
    this.updated_at = "";
  }
}