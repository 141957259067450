// Models
import {AppPartnerIndex} from "@/models/app/partner";
import {AppInvoiceTypeIndex} from "@/models/app/invoice-type";
import {AppIncomingInvoiceItemIndex} from "@/models/app/incoming-invoice-item";

export interface AppIncomingInvoiceIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  invoice_type_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    accountNumber: string;
    paymentDescription: string;
    model: string;
    referenceNumber: string;
    listOfItemsStatus: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  }
  created_at: string;
  updated_at: string;
  partner: AppPartnerIndex;
  invoice_type: AppInvoiceTypeIndex;
  incoming_invoice_items: Array<AppIncomingInvoiceItemIndex>;
}

export class AppIncomingInvoiceIndex implements AppIncomingInvoiceIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  invoice_type_id: string | string[];
  content: {
    year: string;
    invoiceNumber: string;
    invoiceNumberInYear: string;
    accountNumber: string;
    paymentDescription: string;
    model: string;
    referenceNumber: string;
    listOfItemsStatus: string;
    amount: {
      hrk: string,
      eur: string,
    };
    paidAmount: {
      hrk: string,
      eur: string,
    };
    status: string;
    date: string;
    dateOfExchangeRate: string;
    dateOfClosing: string;
  }
  created_at: string;
  updated_at: string;
  partner: AppPartnerIndex;
  invoice_type: AppInvoiceTypeIndex;
  incoming_invoice_items: Array<AppIncomingInvoiceItemIndex>;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.partner_id = "";
    this.invoice_type_id = "";
    this.content = {
      year: "",
      invoiceNumber: "",
      invoiceNumberInYear: "",
      accountNumber: "",
      paymentDescription: "",
      model: "",
      referenceNumber: "",
      listOfItemsStatus: "0",
      amount: {
        hrk: "",
        eur: "",
      },
      paidAmount: {
        hrk: "",
        eur: "",
      },
      status: "0",
      date: "",
      dateOfExchangeRate: "",
      dateOfClosing: "",
    };
    this.created_at = "";
    this.updated_at = "";
    this.partner = new AppPartnerIndex();
    this.invoice_type = new AppInvoiceTypeIndex();
    this.incoming_invoice_items = [];
  }
}