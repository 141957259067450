// Store
import store from "@/store";

export interface AppInvoicePaymentCreateOutgoingInvoiceCompensationPaymentIndex {
  organization_id: string | string[];
  incoming_invoice_id: string | string[];
  outgoing_invoice_id: string | string[];
  payment_type_id: string | string[];
  transaction_id: string | string[];
  type: string;
  partner_id: string;
  content: {
    date: string;
    amount: {
      hrk: string,
      eur: string,
    };
  };
  is_compensation: boolean;
  compensation_ordinal_number: number | null;
  compensation_year: number | null;
}

export class AppInvoicePaymentCreateOutgoingInvoiceCompensationPaymentIndex implements AppInvoicePaymentCreateOutgoingInvoiceCompensationPaymentIndex {
  organization_id: string | string[];
  incoming_invoice_id: string | string[];
  outgoing_invoice_id: string | string[];
  payment_type_id: string | string[];
  transaction_id: string | string[];
  type: string;
  partner_id: string;
  content: {
    date: string;
    amount: {
      hrk: string,
      eur: string,
    };
  };
  is_compensation: boolean;
  compensation_ordinal_number: number | null;
  compensation_year: number | null;

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.incoming_invoice_id = "";
    this.outgoing_invoice_id = "";
    this.payment_type_id = "";
    this.transaction_id = "";
    this.type = "standard";
    this.partner_id = "";
    this.content = {
      date: "",
      amount: {
        hrk: "",
        eur: "",
      },
    };
    this.is_compensation = false;
    this.compensation_ordinal_number = null;
    this.compensation_year = null;
  }
}