// Store
import store from "@/store";

export interface AppInvoicePaymentCreateOutgoingInvoicePaymentIndex {
  organization_id: string | string[];
  outgoing_invoice_id: string | string[];
  payment_type_id: string | string[];
  transaction_id: string | string[];
  content: {
    date: string;
    amount: {
      hrk: string,
      eur: string,
    };
  };
  is_compensation: boolean;
}

export class AppInvoicePaymentCreateOutgoingInvoicePaymentIndex implements AppInvoicePaymentCreateOutgoingInvoicePaymentIndex {
  organization_id: string | string[];
  outgoing_invoice_id: string | string[];
  payment_type_id: string | string[];
  transaction_id: string | string[];
  content: {
    date: string;
    amount: {
      hrk: string,
      eur: string,
    };
  };
  is_compensation: boolean;

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.outgoing_invoice_id = "";
    this.payment_type_id = "";
    this.transaction_id = "";
    this.content = {
      date: "",
      amount: {
        hrk: "",
        eur: "",
      },
    };
    this.is_compensation = false;
  }
}