// Models
import {AppPartnerIndex} from "@/models/app/partner";
import {AppPaymentTypeIndex} from "@/models/app/payment-type";
import {AppExcerptIndex} from "@/models/app/excerpt";
import {AppInvoicePaymentIndex} from "@/models/app/invoice-payment";

export interface AppTransactionIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  excerpt_id: string | string[];
  payment_type_id: string | string[];
  content: {
    ordinalNumber: string;
    paymentPurpose: string;
    amount: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  partner: AppPartnerIndex;
  payment_type: AppPaymentTypeIndex;
  excerpt: AppExcerptIndex;
  invoice_payments: Array<AppInvoicePaymentIndex>;
}

export class AppTransactionIndex implements AppTransactionIndex {
  id: string;
  organization_id: string | string[];
  partner_id: string | string[];
  excerpt_id: string | string[];
  payment_type_id: string | string[];
  content: {
    ordinalNumber: string;
    paymentPurpose: string;
    amount: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  partner: AppPartnerIndex;
  payment_type: AppPaymentTypeIndex;
  excerpt: AppExcerptIndex;
  invoice_payments: Array<AppInvoicePaymentIndex>;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.partner_id = "";
    this.excerpt_id = "";
    this.payment_type_id = "";
    this.content = {
      ordinalNumber: "",
      paymentPurpose: "",
      amount: {
        hrk: "",
        eur: "",
      },
    };
    this.created_at = "";
    this.updated_at = "";
    this.partner = new AppPartnerIndex();
    this.payment_type = new AppPaymentTypeIndex();
    this.excerpt = new AppExcerptIndex();
    this.invoice_payments = [];
  }
}