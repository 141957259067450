// Models
import {AppIncomingInvoiceIndex} from "@/models/app/incoming-invoice";
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";
import {AppPaymentTypeIndex} from "@/models/app/payment-type";
import {AppTransactionIndex} from "@/models/app/transaction";

export interface AppInvoicePaymentIndex {
  id: string;
  organization_id: string | string[];
  incoming_invoice_id: string | string[];
  outgoing_invoice_id: string | string[];
  payment_type_id: string | string[];
  transaction_id: string | string[];
  content: {
    date: string;
    amount: {
      hrk: string,
      eur: string,
    };
  };
  is_compensation: boolean;
  created_at: string;
  updated_at: string;
  incoming_invoice: AppIncomingInvoiceIndex;
  outgoing_invoice: AppOutgoingInvoiceIndex;
  payment_type: AppPaymentTypeIndex;
  transaction: AppTransactionIndex;
}

export class AppInvoicePaymentIndex implements AppInvoicePaymentIndex {
  id: string;
  organization_id: string | string[];
  incoming_invoice_id: string | string[];
  outgoing_invoice_id: string | string[];
  payment_type_id: string | string[];
  transaction_id: string | string[];
  content: {
    date: string;
    amount: {
      hrk: string,
      eur: string,
    };
  };
  is_compensation: boolean;
  created_at: string;
  updated_at: string;
  incoming_invoice: AppIncomingInvoiceIndex;
  outgoing_invoice: AppOutgoingInvoiceIndex;
  payment_type: AppPaymentTypeIndex;
  transaction: AppTransactionIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.incoming_invoice_id = "";
    this.outgoing_invoice_id = "";
    this.payment_type_id = "";
    this.transaction_id = "";
    this.content = {
      date: "",
      amount: {
        hrk: "",
        eur: "",
      },
    };
    this.is_compensation = false;
    this.created_at = "";
    this.updated_at = "";
    this.incoming_invoice = new AppIncomingInvoiceIndex();
    this.outgoing_invoice = new AppOutgoingInvoiceIndex();
    this.payment_type = new AppPaymentTypeIndex();
    this.transaction = new AppTransactionIndex();
  }
}