// Models
import {AppBankIndex} from "@/models/app/bank";

export interface AppExcerptIndex {
  id: string;
  organization_id: string | string[];
  bank_id: string | string[];
  content: {
    ordinalNumber: string;
    date: string;
    initialBalance: {
      hrk: string,
      eur: string,
    };
    balance: {
      hrk: string,
      eur: string,
    };
    totalDebt: {
      hrk: string,
      eur: string,
    };
    totalDemand: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  bank: AppBankIndex;
}

export class AppExcerptIndex implements AppExcerptIndex {
  id: string;
  organization_id: string | string[];
  bank_id: string | string[];
  content: {
    ordinalNumber: string;
    date: string;
    initialBalance: {
      hrk: string,
      eur: string,
    };
    balance: {
      hrk: string,
      eur: string,
    };
    totalDebt: {
      hrk: string,
      eur: string,
    };
    totalDemand: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  bank: AppBankIndex;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.bank_id = "";
    this.content = {
      ordinalNumber: "",
      date: "",
      initialBalance: {
        hrk: "",
        eur: "",
      },
      balance: {
        hrk: "",
        eur: "",
      },
      totalDebt: {
        hrk: "",
        eur: "",
      },
      totalDemand: {
        hrk: "",
        eur: "",
      },
    };
    this.created_at = "";
    this.updated_at = "";
    this.bank = new AppBankIndex();
  }
}