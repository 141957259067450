// Models
import {AppPlaceIndex} from "@/models/app/place";
import {AppPartnerAccountIndex} from "@/models/app/partner-account";

export interface AppPartnerIndex {
  id: string;
  organization_id: string | string[];
  place_id: string | string[];
  content: {
    title: string;
    address: string;
    phone: string;
    identificationNumber: string;
    vatNumber: string;
    paidEstimateExit: {
      hrk: string,
      eur: string,
    };
    paidEstimateEntry: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  place: AppPlaceIndex;
  partner_accounts: Array<AppPartnerAccountIndex>;
}

export class AppPartnerIndex implements AppPartnerIndex {
  id: string;
  organization_id: string | string[];
  place_id: string | string[];
  content: {
    title: string;
    address: string;
    phone: string;
    identificationNumber: string;
    vatNumber: string;
    paidEstimateExit: {
      hrk: string,
      eur: string,
    };
    paidEstimateEntry: {
      hrk: string,
      eur: string,
    };
  }
  created_at: string;
  updated_at: string;
  place: AppPlaceIndex;
  partner_accounts: Array<AppPartnerAccountIndex>;

  constructor() {
    this.id = "";
    this.organization_id = "";
    this.content = {
      title: "",
      address: "",
      phone: "",
      identificationNumber: "",
      vatNumber: "",
      paidEstimateExit: {
        hrk: "0",
        eur: "0",
      },
      paidEstimateEntry: {
        hrk: "0",
        eur: "0",
      },
    };
    this.created_at = "";
    this.updated_at = "";
    this.place = new AppPlaceIndex();
    this.partner_accounts = [];
  }
}